import images from "./images";

const imageGallery = [
  {
    url: images.about02,
    title: "Image 1",
  },
  {
    url: images.adidas,
    title: "Image 5",
  },
  {
    url: images.amazon,
    title: "Image 3",
  },
];

export default imageGallery;
