import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import ReactTooltip from "react-tooltip";
import "./Skills.scss";
import { v4 as uuidv4 } from "uuid";
import { LanguageContext } from "../../App";

const Skills = () => {
  const [toggleCzech] = useContext(LanguageContext);
  const [activeFilter, setActiveFilter] = useState("Life 1.0");
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterExperiences, setFilterExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    const skillsQuery = `*[_type == "skills"]`;
    const experienceQuery = `*[_type == "experiences"] | order(order asc)`;
    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
      setFilterSkills(data.filter((d) => d.tags.includes("Life 1.0")));
    });

    client.fetch(experienceQuery).then((data) => {
      setExperiences(data);
      setFilterExperiences(data.filter((d) => d.tags.includes("Life 1.0")));
    });
  }, []);

  const handleFilter = (item) => {
    setActiveFilter(item);
    setFilterSkills(skills.filter((skill) => skill.tags.includes(item)));
    setFilterExperiences(experiences.filter((exp) => exp.tags.includes(item)));
  };

  return (
    <>
      {toggleCzech ? (
        <h2 className="head-text">
          Moje zkušenosti & <span> dovednosti </span>
        </h2>
      ) : (
        <h2 className="head-text">
          My skills & <span>experience</span>
        </h2>
      )}

      <div className="app__skills-filter">
        {["Life 1.0", "Life 1.1 (Beta)"].map((item, index) => (
          <div
            key={uuidv4()}
            onClick={() => handleFilter(item)}
            className={`app__skills-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {filterSkills.map((skill, index) => (
            <motion.div
              key={uuidv4()}
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>

        <motion.div className="app__skills-exp">
          {filterExperiences?.map((experience, index) => (
            <motion.div key={uuidv4()} className="app__skills-exp-item">
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work, index) => (
                  <React.Fragment key={uuidv4()}>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                    >
                      <h4 className="bold-text">
                        {toggleCzech ? work.nameCZ : work.name}
                      </h4>
                      <p className="p-text">
                        {toggleCzech ? work.companyCZ : work.company}
                      </p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {toggleCzech ? work.descCZ : work.desc}
                    </ReactTooltip>
                  </React.Fragment>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);
