import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./OtherLife.scss";
import { client, urlFor } from "../../client";
import { images } from "../../constants";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import { LanguageContext } from "../../App";

const OtherLife = () => {
  const [toggleCzech] = useContext(LanguageContext);
  const [otherLife, setOtherLife] = useState([]);
  useEffect(() => {
    const query = '*[_type == "otherLife"] | order(order asc)';
    client.fetch(query).then((data) => {
      setOtherLife(data);
    });
  }, []);

  return (
    <>
      {toggleCzech ? (
        <h2 className="head-text">
          Poodhalení mojí dosavadní <span>životní cesty</span>
        </h2>
      ) : (
        <h2 className="head-text">
          A sneak peak into <span>my life journey</span> so far
        </h2>
      )}

      <div className="app__other-container">
        {otherLife.map((exp, index) => (
          <React.Fragment key={uuidv4()}>
            <div className="app__other-item">
              <div className="other-title">
                <h2
                  className="bold-text"
                  data-tip
                  data-for={toggleCzech ? exp.yearsCZ : exp.years}
                >
                  {toggleCzech ? exp.titleCZ : exp.title}
                </h2>
              </div>
              <div className="other-data">
                <div className="other-text">
                  <p className="p-text">
                    {toggleCzech ? exp.descriptionCZ : exp.description}
                  </p>
                </div>

                <img
                  src={urlFor(exp.imgUrl)}
                  alt={exp.title}
                  className="other-image"
                />
              </div>
            </div>

            <ReactTooltip
              id={toggleCzech ? exp.yearsCZ : exp.years}
              effect="solid"
              arrowColor="#fff"
              className="skills-tooltip"
            >
              {exp.years}
            </ReactTooltip>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(OtherLife, "app__other"),
  "other",
  "app__primarybg"
);
