import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";
import { client, urlFor } from "../../client";
import { LanguageContext } from "../../App";

const About = () => {
  const [abouts, setAbouts] = useState([]);
  const [aboutTagline, setAboutTagline] = useState([]);
  const [toggleCzech] = useContext(LanguageContext);

  useEffect(() => {
    const query = '*[_type == "abouts"] | order(order asc)';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  useEffect(() => {
    const taglineQuery = '*[_type == "moreAboutMe"]';

    client.fetch(taglineQuery).then((data) => {
      setAboutTagline(data);
    });
  }, []);

  return (
    <>
      {toggleCzech ? (
        <h2 className="head-text">
          Pojďme spolu <span>vytvořit něco skvělého</span>.
          <br /> To jediné, co nás může zastavit, je naše{" "}
          <span>představivost</span>.
        </h2>
      ) : (
        <h2 className="head-text">
          Let's build <span>something amazing</span> together.
          <br /> <span>Imagination</span> is our only limit.
        </h2>
      )}

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>
              {toggleCzech ? about.titleCZ : about.title}
            </h2>
            <p className="p-text" style={{ marginTop: 10 }}>
              {toggleCzech ? about.descriptionCZ : about.description}
            </p>
          </motion.div>
        ))}
      </div>
      <div className="personal-container">
        {toggleCzech ? (
          <p className="personal-text">
            Věřím, že nezáleží na tom, co se rozhodnete studovat. Důležité je,
            abyste se <strong>nikdy nepřestali učit</strong>. Abyste se vždy
            snažili být tou nejlepší verzí sebe sama. A nikdy se{" "}
            <strong>nespokojili s tím, že jste jen ‚‚průměrní‘‘. </strong>
            Proto se snažím upgradovat svůj život z <strong>
              verze 1.0{" "}
            </strong>{" "}
            (ve kterém jsem politolog a bezpečnostní expert) na{" "}
            <strong>verzi 1.1</strong> (ve kterém jsem webový developer -
            samouk). Můj nový život je sice ještě v beta verzi, ale ladím, jak
            jen to jde.
          </p>
        ) : (
          <p className="personal-text">
            I believe it does not matter what you decide to study at university.
            What matters is that you <strong>never stop learning</strong>. That
            you always try to be the best version of yourself. And you{" "}
            <strong>never settle for being just "average"</strong>. That is why
            I am trying to upgrade my life from <strong>version 1.0 </strong>{" "}
            (where I am a political scientist and a security expert) to{" "}
            <strong>version 1.1</strong> (where I am a self-taught web
            developer). My new life might still be in beta, but I debug as much
            as I can.
          </p>
        )}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
