import React, { useContext, useState } from "react";
import "./Navbar.scss";
import { images } from "../../constants";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { LanguageContext } from "../../App";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [toggleCzech, setToggleCzech] = useContext(LanguageContext);

  const menuItems = [
    "home",
    "about",
    "other",
    "skills",
    "work",
    "contact",
    "language",
  ];

  function toggleLanguage() {
    setToggleCzech(!toggleCzech);
  }

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo} alt="" />
      </div>

      <ul className="app__navbar-links">
        {menuItems.map((item) =>
          item === "home" ? (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div></div>
              <a href={`#${item}`}>{toggleCzech ? "Domů" : "Homepage"}</a>
            </li>
          ) : item === "about" ? (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div></div>
              <a href={`#${item}`}>{toggleCzech ? "O mně" : "About me"}</a>
            </li>
          ) : item === "other" ? (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div></div>
              <a href={`#${item}`}>
                {toggleCzech ? "Můj život v1.0" : "My life v1.0"}
              </a>
            </li>
          ) : item === "skills" ? (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div></div>
              <a href={`#${item}`}>
                {toggleCzech ? "Moje dovednosti" : "My skills"}
              </a>
            </li>
          ) : item === "work" ? (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div></div>
              <a href={`#${item}`}>
                {toggleCzech ? "Moje projekty" : "My portfolio"}
              </a>
            </li>
          ) : item === "contact" ? (
            <li className="app__flex p-text" key={`link-${item}`}>
              <div></div>
              <a href={`#${item}`}>
                {toggleCzech ? "Kontaktujte mě" : "Contact me"}
              </a>
            </li>
          ) : (
            <li className="app__flex p-text language-test" key={`link-${item}`}>
              <div></div>
              <a
                href={`#${item}`}
                onClick={() => setToggleCzech((prevLanguage) => !prevLanguage)}
              >
                {toggleCzech ? "English" : "Česky"}
              </a>
            </li>
          )
        )}
      </ul>
      <ul className="language-nav">
        <li className="app__flex p-text language-test" key="key-languageSmall">
          <a
            href="#languageSmall"
            onClick={() => setToggleCzech((prevLanguage) => !prevLanguage)}
          >
            {toggleCzech ? "English" : "Česky"}
          </a>
        </li>
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [200, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul className="app__navbar-links">
              {menuItems.map((item) =>
                item === "home" ? (
                  <li className="app__flex p-text" key={`link-${item}`}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {toggleCzech ? "Domů" : "Homepage"}
                    </a>
                  </li>
                ) : item === "about" ? (
                  <li className="app__flex p-text" key={`link-${item}`}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {toggleCzech ? "O mně" : "About me"}
                    </a>
                  </li>
                ) : item === "other" ? (
                  <li className="app__flex p-text" key={`link-${item}`}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {toggleCzech ? "Můj život v1.0" : "My life v1.0"}
                    </a>
                  </li>
                ) : item === "skills" ? (
                  <li className="app__flex p-text" key={`link-${item}`}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {toggleCzech ? "Moje dovednosti" : "My skills"}
                    </a>
                  </li>
                ) : item === "work" ? (
                  <li className="app__flex p-text" key={`link-${item}`}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {toggleCzech ? "Moje projekty" : "My portfolio"}
                    </a>
                  </li>
                ) : item === "contact" ? (
                  <li className="app__flex p-text" key={`link-${item}`}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {toggleCzech ? "Kontaktujte mě" : "Contact me"}
                    </a>
                  </li>
                ) : (
                  <li
                    className="app__flex p-text"
                    key={`link-${item}`}
                    id="test"
                  >
                    <div></div>
                    <a
                      href={`#${item}`}
                      onClick={() =>
                        setToggleCzech(
                          (prevLanguage) => !prevLanguage,
                          setToggle(false)
                        )
                      }
                    >
                      {toggleCzech ? "English" : "Česky"}
                    </a>
                  </li>
                )
              )}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
