import React, { useState, useContext } from "react";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import "./Footer.scss";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaGithub,
  FaLinkedin,
} from "react-icons/fa";
import { LanguageContext } from "../../App";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [toggleCzech] = useContext(LanguageContext);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: name,
      email: email,
      message: message,
    };

    client.create(contact).then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    });
  };

  return (
    <>
      {toggleCzech ? (
        <h2 className="head-text">
          Pojďte na kafe a <span>pokecat</span>!
        </h2>
      ) : (
        <h2 className="head-text">
          Take a coffee and <span>let's chat</span>!
        </h2>
      )}

      <div className="app__footer-touch">
        <div className="app__footer-cards">
          <div className="app__footer-card">
            <img src={images.email} alt="email" />
            <a
              href="mailto:jan@michalcak.eu"
              className="p-text app__footer-card-email-text"
            >
              jan@michalcak.eu
            </a>
          </div>
        </div>

        <div className="app__footer-cards">
          <div className="app__footer-card app__footer-social">
            <a
              href="https://github.com/mrcrazyog"
              target="_blank"
              rel="noreferrer"
            >
              <FaGithub className="app__footer-social-icon" />
            </a>
          </div>
          <div className="app__footer-card app__footer-social">
            <a
              href="https://linkedin.com/in/janmichalcak"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin className="app__footer-social-icon" />
            </a>
          </div>
          <div className="app__footer-card app__footer-social">
            <a
              href="https://twitter.com/jan.michalcak"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter className="app__footer-social-icon" />
            </a>
          </div>
          <div className="app__footer-card app__footer-social">
            <a
              href="https://facebook.com/michalcak.jan"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook className="app__footer-social-icon" />
            </a>
          </div>
          <div className="app__footer-card app__footer-social">
            <a
              href="https://instagram.com/czechoslovakijan"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="app__footer-social-icon" />
            </a>
          </div>
        </div>
      </div>

      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              type="text"
              className="p-text"
              placeholder={toggleCzech ? "Vaše jméno" : "Your Name"}
              name="name"
              value={name}
              onChange={handleChangeInput}
            />
          </div>
          <div className="app__flex">
            <input
              type="email"
              className="p-text"
              placeholder={toggleCzech ? "Váš email" : "Your Email"}
              name="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder={toggleCzech ? "Vaše zpráva" : "Your Messaage"}
              value={message}
              name="message"
              onChange={handleChangeInput}
              cols="30"
              rows="10"
            />
          </div>

          {toggleCzech ? (
            <button type="button" className="p-text" onClick={handleSubmit}>
              {" "}
              {!loading ? "Poslat zprávu" : "Posílám..."}
            </button>
          ) : (
            <button type="button" className="p-text" onClick={handleSubmit}>
              {" "}
              {!loading ? "Send Message" : "Sending..."}
            </button>
          )}
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            {" "}
            {toggleCzech
              ? "Děkuji, že jste se se mnou spojili! Ozvu se Vám!"
              : "Thank you for getting in touch! I will get back to you!"}
          </h3>
        </div>
      )}

      <div className="copyright">
        <p className="p-text">© {currentYear} Jan Michalcak</p>
        <p className="p-text">Created with ❤️ in Prague, Czechia</p>
        <p className="p-text">All rights reserved</p>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
