import React, { useState, useContext } from "react";

import {
  About,
  Footer,
  Header,
  Skills,
  Testimonial,
  Work,
  OtherLife,
} from "./container";

import { Navbar } from "./components";
import "./App.scss";

export const LanguageContext = React.createContext();

function App() {
  const [toggleCzech, setToggleCzech] = useState(false);

  return (
    <div className="app">
      <LanguageContext.Provider value={[toggleCzech, setToggleCzech]}>
        <Navbar />
        <Header />
        <About />
        <OtherLife />
        <Skills />
        <Work />
        <Footer />
      </LanguageContext.Provider>
    </div>
  );
}

export default App;
