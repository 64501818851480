import React from "react";

const menuItems = ["home", "about", "other", "skills", "work", "contact"];

const NavigationDots = ({ active }) => {
  return (
    <div className="app__navigation">
      {menuItems.map((item, index) => (
        <a
          key={item + index}
          className="app__navigation-dot"
          href={`#${item}`}
          style={active === item ? { backgroundColor: "#313BAC" } : {}}
        ></a>
      ))}
    </div>
  );
};

export default NavigationDots;
